"use client";

import { useAccountNicknameLookup } from "#app/(unauthorized)/authentication/useAccountNicknameLookup";
import { Icon } from "#ui/icon";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "#ui/popover";
import { cx } from "#ui/style.utils";

export interface AccountProps {
  accountNumber: string | null | undefined | number;
  className?: string;
  multiline?: boolean;
  multilineWithInfo?: boolean;
  nameOverride?: string;
}

export function Account({
  accountNumber,
  className,
  multiline,
  multilineWithInfo,
  nameOverride,
}: AccountProps) {
  const nicknameLookup = useAccountNicknameLookup();
  const accountType = "[Account type]";

  if (typeof accountNumber == "number") {
    accountNumber = `00000000${accountNumber}`;
    accountNumber = accountNumber.substring(accountNumber.length - 8);
  }

  if (accountNumber == null || accountNumber == undefined) return "N/A";

  let nickname = nameOverride ?? (nicknameLookup[accountNumber] || "");
  if (nickname == accountNumber) nickname = "";

  const l = accountNumber.length;
  const actStr = accountNumber.substring(l - 4);

  if (multiline) {
    return (
      <span className={cx("block text-sm", className)}>
        <span className="block text-shade-70">{nickname}</span>{" "}
        <span className="block text-shade-70">
          {accountType} &hellip;{actStr}
        </span>
      </span>
    );
  }

  if (multilineWithInfo) {
    return (
      <span className={cx("block text-sm", className)}>
        <span className="block">{nickname}</span>
        <span className="flex w-fit items-center gap-5px">
          <span className="text-shade-70">&hellip;{actStr}</span>
          <ToggleFullAccountNumber accountNumber={accountNumber} />
        </span>
        <span className="block text-shade-70">{accountType}</span>
      </span>
    );
  }

  return (
    <span className={cx("block text-sm", className)}>
      {nickname} &hellip;{actStr}
    </span>
  );
}

function ToggleFullAccountNumber({ accountNumber }: { accountNumber: string }) {
  return (
    <Popover>
      <PopoverTrigger>
        <Icon name="ms-info" size="sm" palette="accent" />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent palette="black" size="sm" side="top">
          <div>Full account number: {accountNumber}</div>
          <PopoverArrow palette="black" />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}
